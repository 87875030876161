<template>
  <div class="main-box">
    <!-- 面包屑 -->
    <a-breadcrumb>
      <a-breadcrumb-item>
        <a  @click="goBack">学习中心</a>
      </a-breadcrumb-item>
      <a-breadcrumb-item >
        <a  @click="goBack">{{paperForm.paperName}}</a>
      </a-breadcrumb-item>
      <a-breadcrumb-item style="color: #15b7dd">答题详情</a-breadcrumb-item>
    </a-breadcrumb>

    <!-- 模考名称和倒计时 -->
    <div class="head-box">
      <div class="name-l">
        <h3>{{paperForm.paperName}}</h3>
        <p><span v-show="!isDetail">考试时长：{{paperForm.duration}}</span><span>试卷总分：{{paperForm.totalScore}}分</span><span>题数：{{radioTotal + checkedTotal + switchTotal}}题</span></p>
      </div>
      <div class="time-r" v-show="!isSave">
        <div class="time-inner">
          <img class="timer-img" src="@/assets/image/knowledgeMall/time.png" alt="">
          <span>剩余时间</span>
          <i>{{hour}}:{{minute}}:{{second}}</i>
        </div>
        <span class="save-btn" @click="onSavePaperPopup">交卷</span>
      </div>
      <div class="time-r" v-show="isSave">
        <p class="score">考试结果：<span>{{saveForm.totalScore}}分</span></p>
      </div>
    </div>

    <div class="answer-box">
      <a-spin class="sping" v-show="loading" />
      <!-- 左侧--信息和题号 -->
      <div class="side-number-left">
        <div class="answer-sheet">
          <h4 class="title">答题卡</h4>
          <div class="state" :class="{'state-save' : isSave}">
            <span>{{isSave ? '正确':'已答'}}</span>
            <span>{{isSave ? '错误':'未答'}}</span>
          </div>
        </div>
        <!-- 题号 -->
        <div class="title-number">
          <div class="number-inner" v-if="paperForm.radioList.length">
            <div class="topic-type">
              <span class="type">单选题</span>
              <span v-show="!isDetail">{{radioSelectTotal}}/{{radioTotal}}</span>
            </div>
            <div class="list">
              <span class="item" 
                @click="onAnchor('radioItem',item.libraryId)"
                :class="isSave ? ( (answerRadio[index]) ? 'item-save-yes' : 'item-save-no') : (libraryIdList.indexOf(item.libraryId) != -1 ? 'item-active' : '')" 
                v-for="(item,index) in paperForm.radioList"
                :key="item.libraryId + 'radio'">{{index + 1}}</span>
            </div>
          </div>

          <div class="number-inner" v-if="paperForm.checkedList.length">
            <div class="topic-type">
              <span class="type">多选题</span>
              <span v-show="!isDetail">{{checkedSelectTotal}}/{{checkedTotal}}</span>
            </div>
            <div class="list">
              <span class="item" 
              @click="onAnchor('checkedItem',item.libraryId)"
              :class="isSave ? ( (answerChecked[index]) ? 'item-save-yes' : 'item-save-no') : (libraryIdList.indexOf(item.libraryId) != -1 ? 'item-active' : '')" 
              v-for="(item,index) in paperForm.checkedList"
              :key="item.libraryId + 'checke'">{{radioTotal + index + 1}}</span>
            </div>
          </div>

          <div class="number-inner" v-if="paperForm.switchList.length">
            <div class="topic-type">
              <span class="type">判断题</span>
              <span v-show="!isDetail">{{switchSelectTotal}}/{{switchTotal}}</span>
            </div>
            <div class="list">
              <span class="item" 
              @click="onAnchor('switchItem',item.libraryId)" 
              :class="isSave ? ( (answerSwitch[index]) ? 'item-save-yes' : 'item-save-no') : (libraryIdList.indexOf(item.libraryId) != -1 ? 'item-active' : '')" 
              v-for="(item,index) in paperForm.switchList"
              :key="item.libraryId + 'switch'">{{radioTotal + checkedTotal + index + 1}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 右侧--作答区 -->
      <div class="side-topic-left">
        <div class="system-inner">

          <!-- 单选题 -->
          <div class="topic-list" v-if="paperForm.radioList.length">
            <div class="topic-item-title" v-html="paperForm.radioTitle"></div>
            <div class="question-item" :id="'radioItem' + item.libraryId" v-for="(item,index) in paperForm.radioList" :key="item.libraryId + 'radioItem'">
              <p class="question-name">{{index + 1}}、{{item.title}}</p>
              <div class="option-child">
                <div v-for="(childItem,i) in item.optionVoList" :key="item.libraryId +'-' + childItem.optionId">
                  <a-radio class="option-item"
                    :disabled="isSave"
                    :checked="childItem.isCheked==1"
                    @click="onSingleChoice(item.libraryId, item.optionVoList, childItem)"
                    :value="i" 
                    >
                  </a-radio>
                  <span :class="{'active' : childItem.isCheked==1}">{{childItem.letter}}、{{childItem.optionName}}</span>
                </div>
                <img class="answer-icon" v-show="isSave" :src="answerRadio[index] ? examAnswerTrue : examAnswerFalse" alt="">
              </div>
              <!-- 答案区 -->
              <div class="answer-inner" v-show="isSave">
                <p>正确答案：<span v-for="(itemTrue, x) in FunAnswer(item.optionVoList, 1)" :key="x + '-' + item.libraryId">{{itemTrue}}</span></p>
                <p class="self" v-if="FunAnswer(item.optionVoList, 2).length">您的答案：<span v-for="(itemTrue, y) in FunAnswer(item.optionVoList, 2)" :key="y + '+' + item.libraryId">{{itemTrue}}</span></p>
                <p class="self" v-else>您的答案：未选</p>
                <p class="txt" v-show="item.answer"><i>题目解析</i> {{item.answer}}</p>
              </div>
            </div>
          </div>

          <!-- 多选题 -->
          <div class="topic-list" v-if="paperForm.checkedList.length">
            <div class="topic-item-title" v-html="paperForm.checkedTitle"></div>
            <div class="question-item" :id="'checkedItem' + item.libraryId" v-for="(item,index) in paperForm.checkedList" :key="item.libraryId + 'checkedItem'">
              <p class="question-name">{{radioTotal + index + 1}}、{{item.title}}</p>
              <div class="option-child">
                <div v-for="(childItem,i) in item.optionVoList" :key="item.libraryId +'-' + childItem.optionId">
                  <a-checkbox 
                    class="option-item"
                    :disabled="isSave"
                    :checked="childItem.isCheked==1"
                    @click="onMultipleChoice(item.libraryId,item.optionVoList,childItem)"
                    :value="i"
                   >
                  </a-checkbox>
                  <span :class="{'active' : childItem.isCheked==1}">{{childItem.letter}}、{{childItem.optionName}}</span>
                </div>
                <img class="answer-icon" v-show="isSave" :src="answerChecked[index] ? examAnswerTrue : examAnswerFalse" alt="">
              </div>
              <!-- 答案区 -->
              <div class="answer-inner" v-show="isSave">
                <p>正确答案：<span>{{FunAnswer(item.optionVoList, 1).join("、")}}</span></p>
                <p class="self" v-if="FunAnswer(item.optionVoList, 2).length">您的答案：<span>{{FunAnswer(item.optionVoList, 2).join("、")}}</span></p>
                <p class="self" v-else>您的答案：未选</p>
                <p class="txt" v-show="item.answer"><i>题目解析</i> {{item.answer}}</p>
              </div>
            </div>
          </div>
          <!-- 判断题 -->
          <div class="topic-list" v-if="paperForm.switchList.length">
            <div class="topic-item-title" v-html="paperForm.switchTitle"></div>
            <div class="question-item" :id="'switchItem' + item.libraryId" v-for="(item,index) in paperForm.switchList" :key="item.libraryId + 'switchItem'">
              <p class="question-name">{{radioTotal + checkedTotal + index + 1}}、{{item.title}}</p>
              <div class="option-child">
                <div v-for="(childItem,i) in item.optionVoList" :key="item.libraryId +'-' + childItem.optionId">
                  <a-radio class="option-item"
                    :disabled="isSave"
                    :checked="childItem.isCheked==1"
                    @click="onSingleChoice(item.libraryId, item.optionVoList, childItem)"
                    :value="i"
                    >
                  </a-radio>
                  <span :class="{'active' : childItem.isCheked==1}">{{childItem.letter}}、{{childItem.optionName}}</span>
                </div>
                <img class="answer-icon" v-show="isSave" :src="answerSwitch[index] ? examAnswerTrue : examAnswerFalse" alt="">
              </div>
              <!-- 答案区 -->
              <div class="answer-inner" v-show="isSave">
                <p>正确答案：<span v-for="(itemTrue, x) in FunAnswer(item.optionVoList, 1)" :key="x + '-' + item.libraryId">{{itemTrue}}</span></p>
                <p class="self" v-if="FunAnswer(item.optionVoList, 2).length">您的答案：<span v-for="(itemTrue, y) in FunAnswer(item.optionVoList, 2)" :key="y + '+' + item.libraryId">{{itemTrue}}</span></p>
                <p class="self" v-else>您的答案：未选</p>
                <p class="txt" v-show="item.answer"><i>题目解析</i> {{item.answer}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TipsModal
      :maskClosable="true"
      :isPublicTipsShow="isPublicTipsShow" 
      :isCancelBtnShow="isCancelBtnShow" 
      :CancelBtnText="CancelBtnText"
      :ConfirmBtnText="ConfirmBtnText" 
      :tipsText="tipsText"
      @setEvent="getEvent" />

    <!-- 成绩弹窗 -->
    <a-modal
      :maskClosable="false"
      :visible="scoreVisible"
      width="730px"
      :closable="false"
    >
      <div class="modal-content">
        <div class="content">
          <div class="score">
            <p class="grade" :class="{'gradePass' : saveForm.pass}">{{saveForm.totalScore}}<span>分</span></p>
            <img class="scoreImg" :src="saveForm.pass ? examPassImg : examNoPassImg" alt="">
          </div>
          <!-- 成绩弹窗底部标签 -->
          <div class="small-lable">
            <div class="lable-item" :class="{'lable-item-pass' : saveForm.pass}">
              <img :src="saveForm.pass ? examActiveIcon1Img : examIcon1Img" alt="">
              <span>用时：<i>{{saveTime}}</i> 分钟</span>
            </div>
            <div class="lable-item" :class="{'lable-item-pass' : saveForm.pass}">
              <img :src="saveForm.pass ? examActiveIcon2Img : examIcon2Img" alt="">
              <span>试卷总分：<i>{{paperForm.totalScore}}</i> 分</span>
            </div>
            <div class="lable-item" :class="{'lable-item-pass' : saveForm.pass}">
              <img :src="saveForm.pass ? examActiveIcon3Img : examIcon3Img" alt="">
              <span>及格分：<i>{{saveForm.passScore}}</i> 分</span>
            </div>
          </div>
        </div>
        <div class="btn-foot">
          <p  class="cancel" @click="scoreVisible=false,$router.go(-1)">退出</p>
          <p  class="all-btn-small" @click="scoreVisible=false,isSave=true">答题情况</p>
        </div>
      </div>
      <template #footer></template>
    </a-modal>

    <!-- 中途退出提示 -->
    <a-modal
      :visible="isOutVisible"
      width="480px"
      :closable="false"
    >
      <div class="modal-contentout">
        <p class="tips">
          <img
            src="@/assets/image/personalCenter/2022050913.png"
            alt=""
          />提示信息
        </p>
        <p class="content">您正在参加模拟考试，是否确认离开？</p>
        <div class="btn-foot">
          <p  class="cancel" @click="$router.go(-1)">交卷离开</p>
          <p class="all-btn-small" @click="isOutVisible = false">继续考试</p>
        </div>
      </div>
      <template #footer></template>
    </a-modal>
  </div>
</template>

<script>
const examNoPassImg = require('@/assets/image/knowledgeMall/exam-noPass.png')  // 未通过考试底图
const examIcon1Img = require('@/assets/image/knowledgeMall/exam-icon1.png')  // 未通过用时 icon
const examIcon2Img = require('@/assets/image/knowledgeMall/exam-icon2.png')  // 未通过总分 icon
const examIcon3Img = require('@/assets/image/knowledgeMall/exam-icon3.png')  // 未通过及格分 icon

const examPassImg = require('@/assets/image/knowledgeMall/exam-pass.png')  // 已通过考试底图
const examActiveIcon1Img = require('@/assets/image/knowledgeMall/exam-active-icon1.png')  // 已通过用时 icon
const examActiveIcon2Img = require('@/assets/image/knowledgeMall/exam-active-icon2.png')  // 已通过总分 icon
const examActiveIcon3Img = require('@/assets/image/knowledgeMall/exam-active-icon3.png')  // 已通过及格分 icon

const examAnswerFalse = require('@/assets/image/knowledgeMall/exam-answer-false.png')  // 答对
const examAnswerTrue = require('@/assets/image/knowledgeMall/exam-answer-true.png')  // 答错

export default {
  // 可用组件的哈希表
  components: { },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      // 未通过图标
      examNoPassImg,
      examIcon1Img,
      examIcon2Img,
      examIcon3Img,
      // 已通过图标
      examPassImg,
      examActiveIcon1Img,
      examActiveIcon2Img,
      examActiveIcon3Img,

      examAnswerFalse,
      examAnswerTrue,

      isScorePass: true,   // 考试成绩是否合格
      tipsText: '', // 提示文案
      isCancelBtnShow: true,   // 控制取消按钮 显示/隐藏
      CancelBtnText: '', // 取消按钮文字
      isConfirmBtnShow: true, // 控制确认按钮 显示/隐藏
      ConfirmBtnText: '', // 确认按钮文字
      isPublicTipsShow: false, 
      isSave: false, // 是否交卷

      libraryIdList: [],  // 存放所有试题的id

      scoreVisible: false,  // 成绩弹窗
      isOutVisible: false, //中途退出弹窗

      paperForm: {   //试卷
        radioList: [], // 单选
        checkedList: [], // 多选
        switchList: [], // 判断
      },

      saveForm: {}, // 已交卷返回数据

      duration: '', // 考试时长
      time: '',

      btnType: 0,  // 1.交卷

      seconds: 0, // 总秒数

      hour:'00', // 时
      minute:'00', // 分
      second:'00', // 秒

      radioTotal:0, // 单选题量
      checkedTotal:0,  // 多选题量
      switchTotal:0,  // 判断题量

      radioSelectTotal:0, // 已选单选题量
      checkedSelectTotal:0,  // 已选多选题量
      switchSelectTotal:0,  // 已选判断题量

      answerRadio:[],  // 单选题所答情况
      answerChecked:[],  // 多选题所答情况
      answerSwitch:[],  // 判断题所答情况

      loading: false, // 加载

      saveTime: '',
      isDetail: false, // 是否查看试卷记录

      submitForm: {  // 提交的试卷表单
        duration: '00:00:00',  // 考试用时
        examId: this.$route.query.configId ? this.$AES.decode_data(this.$route.query.configId) : '',  // 考试id
        examScore: 0,   // 得分
        totalScore: 0, // 得分
        examineeId: this.$store.state.userInfo.userId,  // 考生id
        paperId: this.$route.query.paperId ? this.$AES.decode_data(this.$route.query.paperId) : '',  // 试卷id
        startTime: '',  // 开考时间
        endTime: '',  // 结束时间
        stemJson: [],  // 答题记录
        type: 2,  // 1.考试  2.模拟
      },
    };
  },
  // 事件处理器
  methods: {
    // 返回上一页
    goBack(){
      if(this.isSave){
        this.$router.back()
      } else {
        this.isOutVisible = true
      }
    },
    // 交卷 确认弹窗
    onSavePaperPopup(){
      if(this.loading) return
      this.tipsText = '确认提交试卷？'  // 提示文案
      this.ConfirmBtnText = '确认'  // 确认提示文案
      this.CancelBtnText = '取消'  // 取消按钮文案
      this.isPublicTipsShow = true   // 提示弹窗显示
      this.isCancelBtnShow = true  // 隐藏取消按钮
      this.btnType = 1
    },

    // 确认交卷
    onSavePaper(){
      // 清除倒计时
      clearTimeout(this.timeout)
      clearTimeout(this.timeout2)

      if(this.loading){return}
      this.loading = true
      
      this.$ajax({
        url: "/hxclass-pc/exam/mock/config/save-paper",
        method: "post",
        params: this.onFormatData(),
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          var hour = this.submitForm.duration.split(':')[0]
          var min = this.submitForm.duration.split(':')[1]
          this.saveTime = (Number(hour) * 60) + Number(min)
          this.scoreVisible = true
          this.saveForm = res.data

          /* 检索答题记录情况，将作答正确情况统计 */
          this.answerRadio = this.checkAnswer(this.paperForm.radioList)
          this.answerChecked = this.checkAnswer(this.paperForm.checkedList)
          this.answerSwitch = this.checkAnswer(this.paperForm.switchList)

        }
      });
    },

    // 检索答题的正确情况
    checkAnswer(arry){
      let answerArry = []
      arry.forEach(element => {
        let TrueOption = []  // 正确答案
        let SelectOption = []  // 所选答案
        element.optionVoList.forEach(element => {
          if(element.isCorrect == 1){
            TrueOption.push(element.letter)
          }
          if(element.isCheked == 1){
            SelectOption.push(element.letter)
          }
        });
        //先判断所选的答案两个数组长度要一致，如果不一致那不需要判断直接就不对了。
        if (TrueOption.length == SelectOption.length) {
          //every() 每一个都是true则返回true，如果有一个是false，那么直接返回false；
          let p = TrueOption.every((item, index) => {
            //判断的条件为a数组的每一项在b数组内查找不等于-1就代表有，有就为true，所有都是true就返回true
            return SelectOption.indexOf(item) !== -1;
          });
          // 将正确答案和所选答案进行对比后暂存起来
          answerArry.push(p)
        } else {
          //这里写长度不一致也就是错误的时候的操作。比如提示答案错误
          answerArry.push(false)
        }
      });
      return answerArry
    },

    // 格式化数据
    onFormatData(){
      // 重置答题记录
      this.submitForm.stemJson = []
      this.submitForm.examScore = 0

      const topicList = [...this.paperForm.radioList,...this.paperForm.checkedList,...this.paperForm.switchList]
      let count = 0
      topicList.forEach((element, index) => {
        // 重组答题格式记录
        this.submitForm.stemJson.push(
          {
            libraryId: element.libraryId,  // 题id
            isCorrect: 0,  // 是否正确
            sort: index + 1,  // 题顺序
            score: element.score,  // 分值
            getScore: 0,  // 得分
            stemOptionJson: [],   // 选项
            stemId: element.stemId ?  element.stemId : ''
          }
        )

        count = 0
        element.optionVoList.forEach(child => {
          this.submitForm.stemJson[index].stemOptionJson.push({
            optionId: child.optionId,
            isCheked: child.isCheked == 1 ? child.isCheked : 0,
            isCorrect: child.isCorrect == 1 ? child.isCorrect : 0,
            sort: child.sort,
            stemOptionId : child.stemOptionId ? child.stemOptionId : ''
          })
          child.isCheked = child.isCheked == 1 ? child.isCheked : 0
          if(element.isRadio){  // 判断和单项
            // 如果当前选项为正确选项，且已选，那就表示答对
            if((child.isCheked == 1) && (child.isCorrect == 1)){
              this.submitForm.stemJson[index].isCorrect = 1
              this.submitForm.stemJson[index].getScore = element.score
            }
          } else {  // 多选
            if(child.isCheked != child.isCorrect){
              count ++
            }
          }
        })

        if(!element.isRadio && count==0){
          this.submitForm.stemJson[index].isCorrect = 1
          this.submitForm.stemJson[index].getScore = element.score
        }
        this.submitForm.examScore = this.submitForm.stemJson[index].getScore + this.submitForm.examScore 
        this.submitForm.totalScore = this.submitForm.examScore
        this.submitForm.stemJson[index].stemOptionJson = JSON.stringify(this.submitForm.stemJson[index].stemOptionJson)
      })
      this.submitForm.stemJson = JSON.stringify(this.submitForm.stemJson)

      return this.submitForm   // 返回格式化后的表单数据
    },


    formatDate(time) {
      var date = new Date(time);
      var YY = date.getFullYear() + '-';
      var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
      var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
      var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
      return YY + MM + DD + " " + hh + mm + ss;
    },

    // 计算考试时长
    sumTotalTime(){
      // 兼容ios设备 要把日期格式化
      let date1 = new Date((this.submitForm.startTime).replace(/-/g,'/'))
      let date2 = new Date((this.submitForm.endTime).replace(/-/g,'/'))

      let s1 = date1.getTime(),s2 = date2.getTime();
      let total = (s2 - s1)/1000;

      let day = parseInt(total / (24*60*60));//计算整数天数
      let afterDay = total - day*24*60*60;//取得值算出天数后剩余的转秒数
      let  hour = parseInt(afterDay/(60*60));//计算整数小时数
      let afterHour = total - day*24*60*60 - hour*60*60;//取得算出小时数后剩余的秒数
      let min = parseInt(afterHour/60);//计算整数分
      let afterMin = total - day*24*60*60 - hour*60*60 - min*60;//取得算出分后剩余的秒数

      hour = hour < 10 ? '0' + hour : hour
      min = min < 10 ? '0' + min : min
      afterMin = afterMin < 10 ? '0' + afterMin : afterMin
  
      let examTime = hour + ':' + min + ':' + afterMin
      this.submitForm.duration = examTime  //  考试用时时长
    },

    // 弹窗点击回调
    getEvent(){
      if(this.btnType == 1 || this.btnType == 0){  // 1.交卷  0 超时自动交卷
        this.onSavePaper()
      }
      this.isPublicTipsShow = false  // 提示弹窗关闭
    },

    // 处理答案
    FunAnswer(arry,type){
      let option = []
      arry.forEach(element => {
        if(type == 1){
          if(element.isCorrect == 1){
            option.push(element.letter)
          }
        } else if(type == 2) {
          if(element.isCheked == 1){
            option.push(element.letter)
          }
        }
      })
      
      return option
    },

    // 考试时间到
    timeOut(){
      this.isPublicTipsShow = true  // 提示弹窗显示
      this.tipsText = '考试答题时间已到，考卷自动提交。'  // 提示文案
      this.ConfirmBtnText = '我知道了'  // 确认提示文案
      this.isCancelBtnShow = false  // 隐藏取消按钮
      this.btnType = 0
    },

    // 多选时触发点击时触发，将题目的id暂存到已答数组
    onMultipleChoice(parseID,optionVoList, childItem){
      childItem.isCheked = childItem.isCheked == 1 ? 0 : 1
      // 定义一个变量，用于检测多选题是否有选中
      let isSelect = false
      optionVoList.forEach(element => {
        if(element.isCheked == 1){
          isSelect = true
        }
      })
      // 如果有至少选中一个，则表示该题已答
      if(isSelect){
        if(this.libraryIdList.indexOf(parseID) < 0){
          this.libraryIdList.push(parseID)
        }
      } else {
        let i = this.libraryIdList.indexOf(parseID)
        this.libraryIdList.splice(i, 1)
      }

      this.selectTopic()
    },

    // 单选题和判断题点击时触发，将题目的id暂存到已答数组
    onSingleChoice(parseID,optionVoList, childItem){
      if(this.libraryIdList.indexOf(parseID) < 0){
        this.libraryIdList.push(parseID)
      }

      // 将所选题的选项全部重置为未选
      optionVoList.forEach(element => {
        element.isCheked = 0
      });
      // 将点击的选项 设为已选状态
      childItem.isCheked = 1
      this.selectTopic()
    },

    // 计算已选的题量
    selectTopic() {
      this.radioSelectTotal = 0
      this.checkedSelectTotal = 0
      this.switchSelectTotal = 0

      this.paperForm.radioList.forEach(element => {
        if(this.libraryIdList.indexOf(element.libraryId) != -1){
          this.radioSelectTotal ++
        }
      })

      this.paperForm.checkedList.forEach(element => {
        if(this.libraryIdList.indexOf(element.libraryId) != -1){
          this.checkedSelectTotal ++
        }
      })

      this.paperForm.switchList.forEach(element => {
        if(this.libraryIdList.indexOf(element.libraryId) != -1){
          this.switchSelectTotal ++
        }
      })
    },

    // 点击左侧题号定位到右侧题目--锚点
    onAnchor(name, id){
      const returnEle = document.querySelector('#' + name + id);
      if (!!returnEle) {
        returnEle.scrollIntoView(false);
      }
    },

    // 作答剩余时间
    timeToSec(time) {
      var hour = time.split(':')[0]
      var min = time.split(':')[1]
      var sec = time.split(':')[2]
      var s = Number(hour * 3600) + Number(min * 60) + Number(sec)
      this.seconds = s
      if(this.seconds){
        this.timeout = setInterval(() => {
          this.seconds --
          if(this.seconds == 0) { // 剩余时间为0时 弹出时间到的弹窗，并清除定时器
            clearTimeout(this.timeout)
            this.timeOut()
          }
          this.countDown();
        }, 1000);
      }
    },

    // 考试倒计时
    countDown() {
      let d = parseInt(this.seconds / (24 * 60 * 60));
      d = d < 10 ? "0" + d : d;
      let h = parseInt((this.seconds / (60 * 60)) % 24);
      this.hour = h < 10 ? "0" + h : h;
      let m = parseInt((this.seconds / 60) % 60);
      this.minute = m < 10 ? "0" + m : m;
      let s = parseInt(this.seconds % 60);
      this.second = s < 10 ? "0" + s : s;

      let time = this.hour + ':' + this.minute + ':' + this.second
      let examForm = {
        duration: time,
        startTime: this.submitForm.startTime,
        stemJson: {},
      }

    }, //定时器每过1秒参数减1

    // 获取试卷详情
    getPaperDetail(){
      this.loading = true
      this.$ajax({
        url: "/hxclass-pc/exam/mock/config/mock-paper",
        method: "get",
        params: {
          configId: this.$AES.decode_data(this.$route.query.configId),
          paperId: this.$AES.decode_data(this.$route.query.paperId),
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.loading = false
          this.paperForm = res.data
          this.duration = res.data.duration
          // 答题倒计时
          this.timeToSec(res.data.duration)

          // 获取各个题型有多少道题
          this.radioTotal = res.data.radioList.length
          this.checkedTotal = res.data.checkedList.length
          this.switchTotal = res.data.switchList.length

          // 查询题目解析
          let libraryIds = [...this.paperForm.radioList, ...this.paperForm.checkedList, ...this.paperForm.switchList]
          let IdsArry = []
          libraryIds.forEach(item => {
            IdsArry.push(item.libraryId)
          });
          this.getAnalysisList(IdsArry)
        }
      })
    },

    // 获取用户试卷详情
    getUserPaperDetail(){
      this.isSave = true
      this.loading = true
      this.$ajax({
        url: "/hxclass-pc/exam/mock/config/user/paper/detail",
        method: "get",
        params: {
          userPaperId: this.$AES.decode_data(this.$route.query.userpPaperId),
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          let arry = ['A','B','C','D','E','F']
          res.data.radioList.forEach(element => {
            element.optionList.forEach((item, index) => {
              item.letter = arry[index]
            })
            element.optionVoList = element.optionList
          });
          res.data.checkedList.forEach(element => {
            element.optionList.forEach((item, index) => {
              item.letter = arry[index]
            })
            element.optionVoList = element.optionList
          });
          res.data.switchList.forEach(element => {
            element.optionList.forEach((item, index) => {
              item.letter = arry[index]
            })
            element.optionVoList = element.optionList
          });
          this.loading = false
          this.paperForm = res.data


          /* 检索答题记录情况，将作答正确情况统计 */
          this.answerRadio = this.checkAnswer(this.paperForm.radioList)
          this.answerChecked = this.checkAnswer(this.paperForm.checkedList)
          this.answerSwitch = this.checkAnswer(this.paperForm.switchList)

          let score = this.$AES.decode_data(this.$route.query.examScore).split('/')
          this.saveForm.totalScore = score[0]
          this.paperForm.totalScore = score[1]
    
          // 获取各个题型有多少道题
          this.radioTotal = res.data.radioList.length
          this.checkedTotal = res.data.checkedList.length
          this.switchTotal = res.data.switchList.length


          // 查询题目解析
          let libraryIds = [...this.paperForm.radioList, ...this.paperForm.checkedList, ...this.paperForm.switchList]
          let IdsArry = []
          libraryIds.forEach(item => {
            IdsArry.push(item.libraryId)
          });
          this.getAnalysisList(IdsArry)
        }
      })
    },

    // 查询题目解析
    getAnalysisList(arry){
      this.$ajax({
        url: '/hxclass-pc/library/getLibraryAnswer',
				method: 'post',
        params: {
          libraryIdList: arry,
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          res.data.forEach(item => {
            // 单选
            this.paperForm.radioList.forEach(child => {
              if(item.libraryId == child.libraryId){
                child.answer = item.answer
              }
            });
            // 多选
            this.paperForm.checkedList.forEach(child => {
              if(item.libraryId == child.libraryId){
                child.answer = item.answer
              }
            });
            // 判断
            this.paperForm.switchList.forEach(child => {
              if(item.libraryId == child.libraryId){
                child.answer = item.answer
              }
            });
          });
        }
      })
    },

    // 实时获取结束时间，用于预防异常退出，获取不到结束时间
    getEndTime(){
      this.timeout2 = setInterval(() => {
        var date = new Date()
        this.submitForm.endTime = this.formatDate(date)  // 结束时间
        this.sumTotalTime()
      }, 1000);
    },

    // 页面刷新时触发
    beforeUnloadHandler(e) {
      e.returnValue = "刷新或离开此页面？" // 此处返回任意字符串，不返回null即可，不能修改默认提示内容
    },
  },
  // 生命周期-实例创建完成后调用
  created() { },
  // 生命周期-实例挂载后调用
  mounted() {
    if(this.$route.query.configId){
      this.isDetail = false
      this.getEndTime() // 实时获取考试结束时间
      this.submitForm.startTime = this.formatDate(new Date())  // 开始时间


      // 获取试卷详情
      this.getPaperDetail()
    } else if (this.$route.query.userpPaperId) { // 获取用户试卷详情
      this.isDetail = true
      
      this.getUserPaperDetail()
    }
  },
  beforeDestroy(){
    if(!this.isDetail){
      if(!this.isSave){
        this.onSavePaper()
      }
      // 清除倒计时
      clearTimeout(this.timeout)
      clearTimeout(this.timeout2)
    }
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {
  },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.main-box {
  padding-top: 80px;
  padding-bottom: 60px;
  height: 100vh;
  overflow: hidden;
  .head-box{
    margin-top: 22px;
    padding: 12px 30px;
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .name-l{
      font-weight: 500;
      color: #333333;
      font-family: PingFang SC-Medium, PingFang SC;
      h3{
        font-size: 20px;
        line-height: 23px;
        max-width: 700px;
      }
      p{
        margin-top: 10px;
        line-height: 19px;
        font-size: 14px;
        >span{
          margin-right: 15px;
        }
      }
    }
    .time-r{
      display: flex;
      .time-inner{
        width: 223px;
        height: 50px;
        font-size: 14px;
        border-radius: 12px;
        background: rgba(157, 232, 255, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        .timer-img{
          width: 16px;
          height: 16px;
        }
        span{
          margin: 0 16px 0 9px;
        }
        i{
          font-style: normal;
          font-weight: 400px;
          font-size: 20px;
        }
      }
      .save-btn{
        margin-left: 24px;
        width: 134px;
        height: 50px;
        line-height: 50px;
        background: #15B7DD;
        border-radius: 12px;
        text-align: center;
        font-size: 24px;
        color: #ffffff;
        cursor: pointer;
      }
      .score{
        font-size: 20px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 23px;
        span{
          font-weight: 500;
          color: #333;
          font-size: 32px;
        }
      }
    }
  }

  // 个人信息和作答区
  .answer-box{
    overflow: hidden;
    max-width: 1400px;
    margin: 16px auto 0;
    position: relative;
    height: calc(100% - 100px);
    .sping{
      width: 20px;
      height: 20px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -10px;
      margin-left: -10px;
    }
    // 左侧题号
    .side-number-left{
      max-height: calc(100% - 50px);
      overflow: hidden;
      width: 300px;
      margin-right: 34px;
      float: left;
      background: #FFFFFF;
      border-radius: 8px;
      padding: 24px 16px;
      .title{
        font-weight: 500;
        color: #333333;
      }
      .info-inner{
        margin-top: 8px;
        border-radius: 8px;
        padding: 16px 12px;
        background: rgba(45,116,221, 0.08);
        .line{
          display: flex;
          color: rgba(47,46,65, 0.8);
          line-height: 25px;
          font-weight: 500;
          font-size: 14px;
          span{
            display: inline-block;
            min-width: 70px;
            font-weight: 400;
          }
        }
      }
      .answer-sheet{
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        .state{
          span{
            display: inline-block;
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            &::after{
              content: '';
              display: inline-block;
              width: 10px;
              height: 10px;
              border-radius: 2px;
              margin-left: 2px;
              border: 1px solid #666666;
              position: relative;
              top: 1px;
            }
          }
          span:nth-child(1){
            margin-right: 8px;
            &::after{
              border: none;
              background: @theme;
            }
          }
          .span-save ::after{
            background: #83CE82;
          }
        }
        .state-save{
          span:nth-child(1){
            &::after{
              background: #83CE82;
            }
          }
          span:nth-child(2){
            &::after{
              border: none;
              background: #EC6C01;
            }
          }
        }
      }
      .title-number{
        height: calc(100vh - 380px);
        overflow-y: scroll;
        .number-inner{
          margin-bottom: 16px;
          .topic-type{
            font-size: 12px;
            .type{
              display: inline-block;
              text-align: center;
              line-height: 23px;
              color: #ffffff;
              width: 64px;
              height: 23px;
              background: #E28C09;
              border-radius: 21px;
              margin-right: 15px;
            }
          }
          .list{
            margin-top: 16px;
            .item{
              display: inline-block;
              text-align: center;
              margin-bottom: 16px;
              margin-right: 20px;
              width: 34px;
              height: 34px;
              line-height: 34px;
              border-radius: 4px;
              font-size: 14px;
              color: #666666;
              border: 1px solid #CACACA;
              cursor: pointer;
            }
            .item:nth-child(5n){
              margin-right: 0;
            }
            .item-active{
              background: @theme;
              color: #ffffff;
              border: 1px solid @theme;
            }
            .item-save-yes{
              border: none;
              color: #83CE82;
              width: 34px;
              height: 34px;
              background: url('../../assets/image/knowledgeMall/correct.png');
              background-size:100% 100%;
            }
            .item-save-no{
              border: none;
              color: #EC6C01;
              width: 34px;
              height: 34px;
              background: url('../../assets/image/knowledgeMall/error.png');
              background-size:100% 100%;
            }
          }
        }
      }
      /* 设置滚动条的样式 */
      .title-number::-webkit-scrollbar {
        width:4px;
      }
      /* 滚动槽 */
      .title-number::-webkit-scrollbar-track {
        -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
        border-radius:10px;
        background:rgba(87, 54, 54, 0.1);
      }
      /* 滚动条滑块 */
      .title-number::-webkit-scrollbar-thumb {
        border-radius:10px;
        background:rgba(0,0,0,0.21);
        -webkit-box-shadow:inset006pxrgba(0,0,0,0.2);
      }
    }

    // 右侧作答区
    .side-topic-left{
      float: right;
      width: calc(100% - 334px);
      background: #FFFFFF;
      border-radius: 8px;
      padding: 55px 55px 0;
      padding-right: 10px;
      height: calc(100% - 50px);
      .system-inner{
        overflow-y: scroll;
        overflow-x: hidden;
        padding-right: 5px;
        height: calc(100% - 20px);
        .topic-list{
          .topic-item-title{
            margin-bottom: 16px;
            /deep/ h1{
              display: inline-block;
              font-size: 18px;
              font-weight: 600;
              color: #333333;
              span{
                font-size: 14px;
                font-weight: 400;
                color: rgba(47,46,65, 0.8);
              }
            }
          }
          .question-item{
            // margin-left: 35px;
            .question-name{
              font-size: 18px;
              line-height: 26px;
              font-weight: 400;
              color: #333333;
              margin-bottom: 5px;
              white-space: pre-wrap;
              margin-left: 35px;
            }
            .option-child{
              width: 95%;
              display: inline-block;
              margin-left: 45px;
              position: relative;
              >div{
                align-items: center;
                display: flex;
                span{
                  max-width: 94%;
                  color:#787D82;
                  line-height: 24px;
                }
                .active{
                  color:#333333;
                }
              }
              .answer-icon{
                position: absolute;
                right: 70px;
                bottom: -20px;
                width: 160px;
              }
              .option-item{
                white-space:normal;
                margin: 5px 10px 5px 0 ;
                line-height: 28px;
                color: #666666;
              }
            }
          }
          .answer-inner{
            background: rgba(21, 183, 221, 0.05);
            padding: 24px 22px;
            border-radius: 12px;
            margin-bottom: 30px;
            margin-right: 54px;
            margin-top: 15px;
            p{
              font-size: 18px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 21px;
            }
            .self{
              margin-top: 16px;
              color: #666666;
            }
            .txt{
              margin-top: 7px;
              font-size: 14px;
              line-height: 21px;
              i{
                color: @theme;
                font-style: normal;
              }
            }
          }
        }
      }
      /* 设置滚动条的样式 */
      .system-inner::-webkit-scrollbar {
        width:4px;
      }
      /* 滚动槽 */
      .system-inner::-webkit-scrollbar-track {
        -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
        border-radius:10px;
        background:rgba(87, 54, 54, 0.1);
      }
      /* 滚动条滑块 */
      .system-inner::-webkit-scrollbar-thumb {
        border-radius:10px;
        background:rgba(0,0,0,0.21);
        -webkit-box-shadow:inset006pxrgba(0,0,0,0.2);
      }
    }
  }
}
.modal-content {
  .content {
    text-align: center;
    .score{
      width: 300px;
      margin: 0 auto;
      position: relative;
      .grade{
        font-size: 55px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #747474;
        line-height: 77px;
        position: absolute;
        left: 47%;
        transform: translateX(-50%);
        top: 45px;
        span{
          font-size: 35px;
        }
      }
      .gradePass{
        color: @theme;
      }
      .scoreImg{
        width: 100%;
      }
    }
    .small-lable{
      display: flex;
      justify-content: space-between;
      margin: 40px 60px 0;
      .lable-item{
        width: 150px;
        height: 40px;
        border-radius:  0 20px 20px 0;
        background: #F0F0F0;
        position: relative;
        img{
          position: absolute;
          left: -17px;
          height: 40px;
        }
        span{
          line-height: 40px;
          font-weight: 400;
          color: #666666;
          i{
            font-size: 20px;
            font-style: normal;
            color: @theme;
          }
        }
      }
      .lable-item:nth-child(2){
        width: 177px;
      }
      .lable-item:nth-child(3){
        width: 155px;
      }
      .lable-item-pass{
        background: #E8F8FC;
      }
    }
  }
  .btn-foot {
    display: flex;
    justify-content: center;
    width: 340px;
    margin: 0 auto;
    margin-top: 30px;
    .cancel {
      text-align: center;
      line-height: 36px;
      border-radius: 18px;
      width: 113px;
      height: 36px;
      color: @theme;
      border: 1px solid @theme;
      cursor: pointer;
    }
    .all-btn-small {
      border-radius: 18px;
      width: 113px;
      height: 36px;
      line-height: 36px;
    }
    p{
      margin: 0 30px;
    }
  }
}
.modal-contentout {
  .tips {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    color: @theme;
    img {
      margin-right: 7px;
    }
  }
  .input {
    height: 40px;
    margin-top: 20px;
  }
  .content {
    font-size: 20px;
    font-weight: 400;
    font-family: PingFang SC-Regular, PingFang SC;
    color: #333333;
    line-height: 23px;
    margin: 32px 30px;
  }
  .btn-foot {
    display: flex;
    justify-content: center;
    width: 340px;
    margin: 0 auto;
    margin-top: 30px;
    .cancel {
      text-align: center;
      line-height: 36px;
      border-radius: 18px;
      width: 113px;
      height: 36px;
      color: @theme;
      border: 1px solid @theme;
      cursor: pointer;
    }
    .all-btn-small {
      border-radius: 18px;
      width: 113px;
      height: 36px;
      line-height: 36px;
    }
    p{
      margin: 0 30px;
    }
  }
}
/deep/ .ant-modal-footer {
  display: none;
}
/deep/ .ant-modal {
  top: 260px;
}
</style>
